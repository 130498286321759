<template lang="pug">
v-list-item(
  v-if="showApp"
  style="background:white"
  @click="get_push_url"
  dense)
  v-list-item-content
    v-list-item-title {{itemData.name}}
      v-chip(
        v-if="!itemData.owner"
        class="pa-1 float-right"
        small
        :color="appClass.color"
        outlined) <b>{{appClass.text}}</b>
    v-list-item-subtitle(v-if="itemData.subtitle") {{itemData.subtitle}}
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppItem',
  props: {
    itemData: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      appClass: {
        text: 'Sin Datos', color: 'grey darken-1'
      }
    };
  },
  methods: {
    get_push_url() {
      if (this.itemData.type === 'static_page') {
        window.location.href = this.itemData.url;
      }
      return this.$router.push({ path: this.itemData.url, query: { appType: this.itemData.type } });
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('ui', ['appsBox']),
    ...mapGetters('ui', ['paidVersion']),
    showApp() {
      let show = true;
      if (this.isLoggedIn === false) {
        if (this.itemData.rate.price > 0 && this.itemData.rate.is_free !== true) {
          show = false;
        }
        if (this.itemData.rate.rate_id === 0 && this.itemData.paid_version.length > 0) {
          const paidVersion = this.paidVersion(this.itemData.paid_version[0]);
          if (paidVersion) {
            if (paidVersion.rate.is_free === true) {
              show = false;
            }
          }
        }
      } else {
        if (this.itemData.rate.rate_id === 0 && this.itemData.paid_version.length > 0) {
          const paidVersion = this.paidVersion(this.itemData.paid_version[0]);
          if (paidVersion) {
            if (paidVersion.credits > 0) {
              show = false;
            }
            if (paidVersion.rate.is_free === true) {
              show = false;
            }
          }
        }
        if (this.itemData.rate.rate_id > 0) {
          if (this.itemData.credits === 0 && this.itemData.rate.is_free !== true) {
            show = false;
          }
        }
      }
      return show;
    }
  },
  mounted() {
    this.appClass.color = 'amber accent-4';
    this.appClass.text = 'Avanzada';
    if (this.itemData.rate.rate_id === 0 && this.itemData.paid_version.length > 0) {
      const paidVersion = this.paidVersion(this.itemData.paid_version[0]);
      if (paidVersion.rate.is_free !== true) {
        if (this.isLoggedIn === false) {
          this.appClass.color = 'green';
          this.appClass.text = 'Basic';
        }
        if (this.isLoggedIn === true) {
          if (paidVersion.credits === 0) {
            this.appClass.color = 'green';
            this.appClass.text = 'Basic';
          }
        }
      }
    }
  }
};
</script>

<style scoped>
</style>
