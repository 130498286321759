<template lang="pug">
div(class='pantalla')
  //- BEGIN MAP TOP BAR
  l-control(position="topleft" class="topbar")
    v-row(class="d-flex flex-row" no-gutters)
      //- uncomment line to show sidebar
      v-col(class="d-flex flex-row" cols="12" sm="3" md="3" lg="3" xl="3")
        v-btn.hidden-md-and-up(
          elevation="2"
          fab
          x-small
          title="Menú principal"
          @click.stop="drawer = !drawer")
          v-icon mdi-menu
        meteo-brand.hidden-md-and-up
        v-btn.hidden-sm-and-down(
          elevation="2"
          fab
          x-small
          title="Categoria de aplicaciones"
          @click="showCategories=!showCategories")
          v-icon mdi-menu
        meteo-brand.hidden-sm-and-down
      v-spacer
      // - Buscador de municipios
      v-col.hidden-md-and-down(
        v-if="showSearchBox()"
        class="d-flex flex-row justify-end" cols="12" sm="3" md="3" lg="3" xl="3")
            v-btn.mr-8.hidden-md-and-down(
              small
              rounded
              @click="openSearch"
              class="text-none"
              prepend-icon="mdi-magnify"
              height="32"
              max-width="220"
              style="font-family: 'Roboto'; font-size: 12px; font-weight: 500;"
            )
              v-icon(left) mdi-magnify
              | Buscar Municipio o Ciudad
      v-dialog(v-model="dialog" max-width="400")
        v-toolbar.text-caption(color="primary" dark) Buscar Municipio o Ciudad
        v-autocomplete.search-box.mt-0(
          v-model="selectedCity"
          :items="cities"
          item-text="name"
          item-value="id"
          :search-input.sync="selectedCityName"
          label="Escribe el nombre de la ciudad o municipio"
          hide-no-data
          hide-details
          hide-selected
          clearable
          no-filter
          open-on-clear
          return-object
          prepend-icon="mdi-magnify"
          @blur="closeSearch"
          @focus="openSearch"
        )
        v-card-actions(class="justify-end")
          v-btn(
            text
            @click="closeSearch") Cerrar
      v-col(
        class="col-login d-flex flex-row justify-end" cols="12" sm="3" md="3" lg="3" xl="3")
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn.mr-1(
              v-show="isLoggedIn"
              elevation="2"
              @click="showInfo"
              fab
              x-small
              v-on="on")
              v-icon mdi-card-account-details-outline
          span {{user}}
        v-btn(
          v-show="isLoggedIn"
          elevation="2"
          fab x-small :title="logoutItem.title" @click="userLogout"
        )
          v-icon {{logoutItem.icon}}
        v-btn(
          v-if="!isLoggedIn"
          elevation="2"
          fab x-small :title="loginItem.title" @click="selectItem(loginItem)"
        )
          v-icon {{loginItem.icon}}
        // v-btn(
          fab
          x-small
          class="mt-0"
          @click="toggleTheme"
          title="Activar tema nocturno")
          v-icon(v-if="!$vuetify.theme.dark") mdi-weather-night
          v-icon(v-else) mdi-white-balance-sunny
    //- END MAP TOP BAR
    //- NAV DRAWER
  v-navigation-drawer.bgLeafletControl(
    class="drawer-mobile"
    v-model="drawer"
    absolute
    temporary
    style="z-index:50; position:fixed; translate: 0 0; height: 800;")
    v-list-item.pl-5
      v-list-item-content.pb-0
        v-list-item-title
          meteo-brand
      v-btn(
        icon
        @click="drawer = !drawer")
        v-icon mdi-window-close
    template
      div.pt-0
        v-btn.ml-8.mb-2(
          width="180"
          rounded
          class="text-none text-subtitle-1 premium-button-lg"
          variant="elevated"
          color="white"
          title="Obtener versión paga"
          @click="goPayment"
          outlined)
          v-icon.pr-1 mdi-medal
          <b>Plan Premium</b>
        v-expansion-panels.mx-0.pt-2(
          hover
          v-model="panel"
          multiple)
          v-expansion-panel(
            :style="{background: item.color, color: 'white'}"
            v-for="(item,i) in appsBox"
            :key="i")
            v-expansion-panel-header {{ item.title }}
            v-expansion-panel-content
              app-item(v-for="(subitem, j) in item.apps" :item-data="subitem"
                class="bgLeafletControl")
    //- END NAV DRAWER
    //- BEGIN BOX APPPLICATION
  l-control.hidden-sm-and-down(class="meteo-box-container"
    position="topleft" id="meteo-box-control" v-if="showCategories" )
    v-expansion-panels.ml-1(
      hover
      v-model="panel"
      multiple)
      v-expansion-panel(
        :style="{background: item.color, color: 'white'}"
        v-for="(item,i) in appsBox"
        @mouseleave.native="actionPanel('leave', i)"
        @mouseover.native="actionPanel('over', i)"
        :key="i")
        v-expansion-panel-header {{ item.title }}
        v-expansion-panel-content
          app-item(v-for="(subitem, j) in item.apps" :item-data="subitem" class="bgLeafletControl")
  //- END BOX APPPLICATION
   //- BEGIN MAP BOTTOM BAR
  l-control(class="static-bottom-bar" position="bottomleft")
    v-tooltip(right)
      template(v-slot:activator="{ on, attrs }" v-if="showSearchBox()")
        v-btn.hidden-lg-and-up(
          fab
          x-small
          @click="openSearch"
          v-on="on"
          class="button-search"
        )
          v-icon mdi-magnify
      span Buscar Municipio o Ciudad
    .bottom-control-icon.bgLeafletControl.pt-2(
      v-for="item in staticBottomItems"
      @click="selectItem(item)",
      :title="item.title"
      v-if="showAuthItem(item.requireAuth)"
    )
      i(:class="['fas', item.icon, 'f-meteocolombia']")
    meteo-blog(v-if="showBlog" @mouseleave.native="showBlog = false")
    .bottom-control-icon.bgLeafletControl.pt-2(
      :title="blogItem.title"
      v-show="!showBlog"
      @mouseover="showBlog = true")
      i(:class="['fas', blogItem.icon, 'f-meteocolombia']")
    //- END MAP BOTTOM BAR
  v-dialog(
    v-model="showDialogForm"
    transition="dialog-bottom-transition"
    max-width="550")
    v-card
      v-toolbar.text-caption(color="primary" dark elevation="2" ) {{currentDescription}}
      v-card-text
        component(
          v-bind:is="currentComponent"
          @response="changeForm"
          :key="key"
        )
      v-card-actions(class="justify-end")
        v-btn(
          text
          @click="showDialogForm = false") Cerrar

</template>

<script>
import L from 'leaflet';
import {
  mapState, mapMutations, mapGetters, mapActions
} from 'vuex';
import { LControl } from 'vue2-leaflet';
import FaqForm from '@/components/forms/FaqForm.vue';
import RegisterForm from '@/components/forms/RegisterForm.vue';
import LoginForm from '@/components/forms/LoginForm.vue';
import SubscriptionForm from '@/components/forms/SubscriptionForm.vue';
import CreditManager from '@/components/subscription/CreditManager.vue';
import MeteoBrand from '@/components/ui/MeteoBrand.vue';
import MeteoBlog from '@/components/ui/MeteoBlog.vue';
import MeteoNews from '@/components/ui/MeteoNews.vue';
import MeteoWallet from '@/components/subscription/MeteoWallet.vue';
import UserInfo from '@/components/user/UserInfo.vue';
import AppItem from '@/components/ui/AppItem.vue';
import lfControlMixin from '@/mixins/lfControlMixin';

export default {
  name: 'LfStaticMapFrame',
  components: {
    LControl,
    FaqForm,
    RegisterForm,
    MeteoBrand,
    LoginForm,
    SubscriptionForm,
    MeteoBlog,
    MeteoNews,
    CreditManager,
    MeteoWallet,
    UserInfo,
    AppItem,
  },
  mixins: [lfControlMixin],
  computed: {
    ...mapState('ui', ['appsBox', 'showFaqs']),
    ...mapState('meteocolombia', ['staticTopItems', 'staticBottomItems',
      'loginItem', 'logoutItem', 'registerItem', 'blogItem', 'cities']),
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isLoggedIn', 'showAuthItem']),
    showDialogForm: {
      get() {
        return this.$store.state.ui.showDialogForm;
      },
      set() {
        return this.$store.commit('ui/TOGGLE_FORM_DIALOG');
      }
    },
  },
  watch: {
    showFaqs(newVal) {
      if (newVal) {
        const item = this.staticBottomItems.find((i) => i.key === 'faqs');
        this.selectItem(item);
      }
    },
    selectedCityName(newVal) {
      if (newVal && newVal.length > 1) {
        this.getCities(newVal);
      }
    },
    selectedCity(newVal) {
      this.SET_CITY(newVal);
      this.closeSearch();
    },
  },
  created() {
    // evento para cambiar el formulario que se debe mostrar
    // en el modal
    this.setShowCategories(this.width_screen);
    this.$bus.$on('select-modal-form', (data) => {
      // mostrar el formulario si no esta desplegado
      if (this.showDialogForm === false) {
        this.$store.commit('ui/TOGGLE_FORM_DIALOG');
      }
      // cambiar el componente del formulario
      this.currentComponent = data.component;
      // Poner el titulo al formulario
      this.currentDescription = data.title;
    });
  },
  mounted() {
    // deshabilitar el zoom al mapa sobre el contrl
    const appBox = L.DomUtil.get('meteo-box-control');
    L.DomEvent.on(appBox, 'mousewheel', L.DomEvent.stopPropagation);
    // deshabilitar el zoom al mapa sobre el contrl
    const blog = L.DomUtil.get('meteo-news-box');
    L.DomEvent.on(blog, 'mousewheel', L.DomEvent.stopPropagation);
  },
  methods: {
    ...mapMutations('meteocolombia', ['SET_CITY']),
    ...mapActions('meteocolombia', ['getCities']),
    ...mapMutations('ui', ['OPEN_SIDEBAR']),
    ...mapActions('auth', ['userLogout']),
    showSearchBox() {
      const currentUrl = window.location.href;
      return currentUrl.includes('mapas_col') || currentUrl.includes('calaire_demo');
    },
    openSearch() {
      this.dialog = true;
      this.selectedCityName = '';
      this.selectedCity = null;
    },
    closeSearch() {
      this.dialog = false;
      this.selectedCityName = '';
      this.selectedCity = null;
    },
    /* toggleTheme() {
      this.$vuetify.theme.themes.dark.anchor = '#41b883';
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }, */
    setShowCategories(value) {
      if (value < 768) {
        this.showCategories = false;
      } else {
        this.showCategories = true;
      }
    },
    selectItem(currentItem) {
      if (currentItem.url) {
        this.$router.push(currentItem.url);
      }

      if (currentItem.component) {
        this.currentDescription = currentItem.description;
        this.currentComponent = currentItem.component;
        if (this.showDialogForm === false) {
          console.log('entro if TOGGLE');
          this.$store.commit('ui/TOGGLE_FORM_DIALOG', true);
        }
        this.key += 1;
      }
    },
    actionPanel(action, panel) {
      if (action === 'over') {
        this.panel.push(panel);
      } else {
        this.panel = [];
      }
    },
    showInfo() {
      this.$bus.$emit('select-modal-form', {
        component: 'user-info',
        title: 'Aquí podrá encontrar toda su información personal.'
      });
    },
    changeForm(formName) {
      if (formName === 'registerItem') this.selectItem(this.registerItem);
      else if (formName === 'loginItem') this.selectItem(this.loginItem);
    },
  },
  data() {
    return {
      dialog: false,
      width_screen: window.innerWidth,
      showBlog: true,
      currentDescription: '',
      currentComponent: '',
      panel: [],
      showCategories: true,
      key: 0,
      drawer: null,
      selectedCity: null,
      selectedCityName: '',
    };
  },
};
</script>
<style scoped>
.pantalla {
  width: 100%;
  height: 100%;
}
.v-expansion-panel--active + .v-expansion-panel, .v-expansion-panel--active:not(:first-child) {
  margin-top: 0px;
}

.drawer-mobile .v-expansion-panels .v-expansion-panel   {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.topbar {
  display: flex;
  position: absolute;
  width: 100vw;
}
.div, .v-dialog{
  overflow-y: hidden !important;
}
.v-dialog .v-card .v-card__text {
  overflow-y: scroll;
  max-height: 550px;
}
.mode-dark, col-1 {
  flex: 1 0 18.333333% !important;
  max-width: 130px !important;
}
@media (max-width: 540px) {
  .mode-dark {
    display: none !important;
  }
}

#meteo-box-control {
  margin-top: 55px!important;
  z-index: 1000;
}
.search-box {
  width: 100%;
  padding: 30px;
  background-color: #ffffff;
}
.v-dialog .v-card__actions {
  background-color: #ffffff !important;
}
.button-search {
  margin-bottom: 6px;
  margin-left: 2px ;
}
/* .col-login{
  margin-right: 22px !important;
} */
@media (max-width: 600px) {
  .col-login{
    margin-right: 22px !important;
  }
}
@media (min-width: 601px) {
  .col-login{
    margin-right: 34px !important;
  }
}
@-moz-document url-prefix() {
  .col-login{
    margin-right: 22px !important;
  }
}
.meteo-box-container .v-expansion-panels{
  border-radius: 15px;
}
</style>
