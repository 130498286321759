<template lang="pug">
  div(class="ramp-container")
    div(class="gradient mr-1 mt-1 ml-1")
      span(
        class="grad-step"
        v-for="(color, index) in colors"
        :style="{background: color}")
          span(v-if="index === 0" class="first-item ramp-label" :title="steps[0].title")
            | {{steps[0].item}}
          span(class="ramp-label common-item" :title="steps[parseInt(index / 2, 10) + 1].title")
            | {{(index + 1) % 2 === 0 ? steps[parseInt(index / 2, 10) + 1].item : ''}}
</template>

<script>
import * as chroma from 'chroma-js';

export default {
  props: {
    minVal: {
      type: Number,
      required: true
    },
    maxVal: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      rampName: 'Viridis',
      colors: [],
      steps: [],
    };
  },
  created() {
    this.colors = chroma.scale(this.rampName).colors(12);
  },
  mounted() {
    this.createSteps();
  },
  methods: {
    createSteps() {
      const stepNumber = 6;
      const step = (this.maxVal - this.minVal) / stepNumber;
      for (let i = 0; i < stepNumber; i++) {
        const currentVal = this.minVal + (step * i);
        this.steps.push({
          item: (currentVal).toFixed(2),
          title: currentVal.toFixed(2)
        });
      }
      this.steps.push({
        item: this.maxVal.toFixed(2),
        title: this.maxVal.toFixed(2)
      });
      this.steps = this.steps.reverse();
    },
  },
};
</script>

<style scoped>
.ramp-container {
  display: flex;
  flex-direction: column;
  position: inherit;
}

.gradient {
  width: 30px;
  height: 240px;
}

.grad-step {
  position: relative;
  display: block;
  width: 30px;
  height: 20px;
}

.ramp-label {
  position: absolute;
  left: 35px;
  font-size: 10px;
  width: max-content;
}

.first-item {
  top: -10px
}

.common-item {
  top: 15px
}
</style>
