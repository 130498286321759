<template lang="pug">
  div(class="mt-5 text-center")
    div(class="text-caption mb-5 mx-auto" width="60")
      | Diligencie el formulario y a continuación lo contactará
      | uno de nuestros expertos
    v-card(
      class="mx-auto my-auto meteo-form"
      max-width="400"
      outlined)
      v-form(ref="form" v-model="valid" lazy-validation :id="formId")
        v-text-field(
          v-model="firstName"
          :rules="[rules.required]"
          label="*Nombre(s)"
          required)
        v-text-field(
          v-model="lastName"
          :rules="[rules.required]"
          label="*Apellido(s)"
          required)
        v-text-field(
          v-model="subject"
          :rules="[rules.required]"
          label="*Asunto"
          required)
        v-text-field(
          v-model="email"
          :rules="[rules.required, rules.emailvalid]"
          label="*Correo Electrónico"
          required)
        v-textarea(
          v-model="message"
          :rules="[rules.required]"
          solo
          name="input-7-4"
          label="*Escriba aquí su inquietud")
        motion-captcha(
          :form-id="formId"
          v-model="validatedCaptcha"
          :show-error="captchaError")
        v-btn(
          :disabled="!valid"
          color="success"
          class="mr-4 mt-4"
          @click="sendFaq") Enviar

      ul(class="form-conditions text-caption mt-5 text-left")
        li  - Los campos marcados con asterísco son obligatorios
      v-card-text
      div(class="text-h6") METEOCOLOMBIA
      div(class="text-caption mb-1 mx-auto" width="60") Telefono: +57 314 280 1245
</template>

<script>
import MotionCaptcha from '@/components/ui/MotionCaptcha.vue';
import formMixin from '@/mixins/formMixin.js';

export default {
  name: 'FaqForm',
  components: {
    MotionCaptcha
  },
  mixins: [formMixin],
  data() {
    return {
      formId: 'faq-form',
      firstName: '',
      lastName: '',
      subject: '',
      email: '',
      message: '',
      valid: true,
      rules: {
        required: (value) => !!value || 'Campo obligatorio.',
        min: (v) => v.length >= 8 || 'Minimo 8 caracteres',
        emailvalid: (v) => /.+@.+\..+/.test(v) || 'Debe ingresar un correo valido',
      },
    };
  },
  methods: {
    sendFaq() {
      const isValid = this.validate();
      if (isValid === false) {
        return;
      }
      if (this.validatedCaptcha === false) {
        this.captchaError = true;
        return;
      }
      this.captchaError = false;
    }
  }
};
</script>
