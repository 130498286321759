<template lang="pug">
div
  l-marker(
    v-if="selectedPnt"
    :lat-lng="selectedPnt"
    :icon="icon"
    @ready="markerMixinReady"
    ref="mixinMarker")
  l-control.control-container.bgLeafletControl(
    :style="{width: showTip === true ? '300px' : '50px'}"
    position="topright")
    .control-icon.py-1.px-1(
      v-show="!showTip",
      @click="toggleTip",
      style="cursor: pointer"
      title="Calcular potencial solar"
      id="solar-energy-control"
    )
      v-icon(large) mdi-wind-turbine
    .control-tip(v-show="showTip")
      .text-h5.mb-1
        | Potencial solar
        v-chip(
          v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
          class="pa-1 float-right"
          color="green"
          title="Obtener versión paga"
          @click="goPayment"
          outlined)
          v-icon mdi-cart-arrow-down
          |<b>Comprar</b>
      v-divider
      .my-2.control-internal-content
        | Seleccione un punto sobre el mapa para consultar la radiación solar promedio.
        | Reciba la energía solar aprovechable al oprimir "Calcular"
      v-divider(v-if="selectedPnt")
      .my-2(v-if="selectedPnt")
        div(class="text-subtitle-1 text--subtitleLeafletControl font-weight-light")
          | Punto seleccionado
        div {{selectedPnt.lat}} | {{selectedPnt.lng}}
        div(v-if="rasterVal")
          v-icon(small) mdi-information-outline
          span
            | Radiación solar promedio: <b>{{rasterVal}}</b> m/s
        forecast-variables(ref="variablesControl")
      v-divider(v-if="true")
      error-view(
        v-show="loadError"
        heightVal=300
        :error-msg="errorMsg")
      v-divider
      span.errorLefletControl--text(v-if="errorMsg" class="mt-2 control-error") {{errorMsg}}
      .mt-2.mb-2.d-flex.control-internal-content
        v-row
          v-col(cols="4" class="my-0 py-0")
            v-btn(
              x-small
              color="errorLefletControl"
              class="ma-2 white--text"
              @click="cancel") Cerrar
                v-icon(right dark small) mdi-close-circle
          v-col(cols="3" class="my-0 py-0")
            v-btn(
              :loading="disabledButton"
              :disabled="disabledButton"
              x-small
              color="successLefletControl"
              class="ma-2 white--text"
              @click="createPower") Calcular
                v-icon(right dark small) mdi-check-circle
          v-col(class="mt-0 mb-2 py-0")
            v-btn(
              v-show="loadGraph"
              :loading="disabledButton2"
              :disabled="disabledButton2"
              x-small
              color="successLefletControl"
              class="ma-2 white--text"
              @click="createPdf") Generar pdf
                v-icon(right dark small) mdi-file-download
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import * as d3 from 'd3';
import * as chroma from 'chroma-js';
import { mapState } from 'vuex';
import {
  LMarker,
  LControl,
} from 'vue2-leaflet';
import { icon } from 'leaflet';
import JSCharting from 'jscharting-vue';
import { jsPDF } from 'jspdf';
import markerMixin from '@/mixins/markerMixin.js';
import lfControlMixin from '@/mixins/lfControlMixin.js';
import ForecastLineChart from '@/components/Forecast/ForecastLineChart.vue';
import ErrorView from '@/components/ui/ErrorView.vue';
import {
  serverApi,
  baseWindPowerPath,
  serverUrl,
  windPowerAscii,
  textData,
  pdfName,
  frontendRoute,
  rampLabelFontSize,
} from '../../config.js';
import ForecastVariables from '@/components/Forecast/ForecastVariables.vue';
import { getToken } from '../../utils.js';

export default {
  name: 'SolarEnergyControl',
  components: {
    LMarker,
    LControl,
    JSCharting,
    ForecastLineChart,
    ErrorView,
    ForecastVariables
  },
  mixins: [markerMixin, lfControlMixin],
  data() {
    return {
      icon: icon({
        iconUrl: require('@/assets/marker-icon.png'),
        shadowUrl: require('@/assets/marker-shadow.png'),
        iconSize: [25, 37],
        iconAnchor: [16, 37]
      }),
      loadError: false,
      loadGraph: false,
      solarPower: [],
      graphTitle: '',
      rasterVal: null,
      errorMsg: '',
      selectedPnt: null,
      dataLoaded: false,
      output: null,
      baseWindPowerPath,
      windPowerAscii,
      disabledButton: false,
      disabledButton2: false,
      chartData: null,
    };
  },
  computed: {
    ...mapState('gis', ['initialMapZoom', 'baseLayers', 'mapLat', 'mapLon']),
  },
  async mounted() {
    // obtener capa inicial que se desplegara como raster
    this.loadASCIIVariable(this.windPowerAscii);
  },
  beforeDestroy() {
    // Eliminar las capas canvas cuando se cambia de aplicacion
    this.removeCanvasLayers();
  },
  methods: {
    removeCanvasLayers() {
      // remover la barra de la rampa del mapa
      this.$parent.$parent.map.removeControl(this.scaleControl);
      this.$parent.$parent.map.removeLayer(this.currentVariableLayer);
    },
    lefletMapReady() {
      // una vez el mapa este listo desactivar el zoom con el raton sobre el control
      const elem = L.DomUtil.get('solar-energy-control');
      L.DomEvent.on(elem, 'mousewheel', L.DomEvent.stopPropagation);
    },
    async print() {
      const el = document.getElementById('power-image');
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL'
      };
      this.output = await this.$html2canvas(el, options);
    },
    async createPdf() {
      this.disabledButton2 = true;
      // Default export is a4 paper, portrait, using millimeters for units
      const doc = new jsPDF();
      // escribir texto en el pdf
      const coords = this.mixinMarker.toGeoJSON().geometry.coordinates;
      // doc.setFontType('bold');
      doc.setFontSize(12);
      doc.setFont(undefined, 'bold');
      doc.text(`Estimación del potencial solar para el sitio ${coords[1].toFixed(3)}° latitud ${coords[0].toFixed(3)}° longitud`, 30, 20);
      // Adicionar imagen al documento
      const img = new Image();
      img.src = this.output;
      doc.addImage(img, 'png', 20, 30, 160, 90);
      // Escribir información relacionada con el potencial por altura
      doc.setFontSize(11);
      doc.text(textData.text1, 72, 135);
      doc.setFont(undefined, 'normal');
      doc.text(textData.text2, 78, 145);
      for (let index = 0; index < this.solarPower.length; index++) {
        const power = this.solarPower[index];
        doc.text(`${power.height} metros                 ${power.number.toFixed(3)} Mwh`, 83, 150 + (5 * index));
      }

      doc.text(textData.text3, 20, 170, {
        maxWidth: '180',
        align: 'justify'
      });

      doc.text(textData.text4, 20, 195, {
        maxWidth: '180',
        align: 'justify'
      });

      doc.text(textData.text5, 20, 220, {
        maxWidth: '180',
        align: 'justify'
      });

      doc.save(pdfName);
      this.disabledButton2 = false;
    },
    async loadSolarEnergy() {
      this.loadGraph = false;
      this.disabledButton = true;
      this.dataLoaded = false;
      const coords = this.mixinMarker.toGeoJSON().geometry.coordinates;
      // traer los valores de las variables extra si existen
      const extraParams = this.$refs.variablesControl.urlQueryParams();
      // Conseguir el token si existe para autenticar la petición
      const token = await getToken();
      const headers = {};
      if (token !== '') {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${serverApi}${frontendRoute}/${this.controlModules['wind power']}?lat=${coords[1]}&lon=${coords[0]}${extraParams}`, {
        headers,
      });
      if (response.status === 200) {
        const jsonResp = await response.json();
        // ajustar decimales a la velocidad
        // const speeds = jsonResp.speeds.map((x) => x.toFixed(2));
        const { power, imagePath } = jsonResp;

        // generar titulo del grafico
        this.graphTitle = jsonResp.title.replaceAll('\\u00f3', 'ó').replaceAll('\\u00f1', 'ñ');

        // cargar datos para almacenar en el localStorage
        this.chartData = jsonResp;

        // lanzar la ventana emergente
        // TODO ver si el grafico es el mismo
        this.showForecast(this.chartData, 'powerGraph', this.graphTitle);

        const colorDict = {
          30: 'red',
          50: 'blue',
          80: 'green',
        };

        /* Object.keys(probabilities).forEach((property) => {
          const probability = probabilities[property];
          this.$bus.$emit('add-line-dataset', {
            labels: speeds,
            data: probability.map((x) => x.toFixed(3)),
            fill: false,
            title: `Altura ${property} m`,
            color: colorDict[property],
            deleteDatasets: false,
          });
        }); */

        // crear datos para la ventana emergente

        this.solarPower = [];
        Object.keys(power).forEach((property) => {
          const pw = power[property];
          this.solarPower.push({
            height: property,
            number: pw,
            color: colorDict[property]
          });
        });

        this.output = `${serverUrl}/${imagePath.replace(baseWindPowerPath, '')}`;

        this.dataLoaded = true;
        this.loadError = false;
        this.loadGraph = true;
      } else {
        const data = await response.json();
        this.loadError = true;
        this.dataLoaded = true;
        this.errorMsg = `${response.status} - ${data || response.statusText}`;
      }
      this.disabledButton = false;
    },
    async loadASCIIVariable(resource) {
      this.selectedPnt = null;
      this.rasterVal = null;
      const asc = await d3.text(resource);
      const s = this.$canvasLf.ScalarField.fromASCIIGrid(asc);
      const { range } = s;
      const scale = chroma.scale(['green', 'yellow', 'red']).domain(range);
      const layer = new this.$canvasLf.canvasLayer.scalarField(s, {
        color: scale, // RdPu
        opacity: 0.65
      }).addTo(this.$parent.$parent.map);

      // creacion del control para etiquetar la escala del mapa
      const bar = this.$canvasLf.control.colorBar(scale, range, {
        title: 'Radiación solar promedio (W/m2)',
        units: 'W/m2',
        steps: 100,
        decimals: 1,
        width: 200,
        height: 10,
        position: 'bottomleft',
        background: '#fff',
        textColor: 'black',
        textLabels: range.map((x) => x.toFixed(3)),
        labels: range,
        labelFontSize: rampLabelFontSize
      }).addTo(this.$parent.$parent.map);

      layer.on('click', (e) => {
        let v = e.value;
        if (e.value !== null) {
          v = v.toFixed(3);
        }
        this.rasterVal = v;
      });
      this.currentVariableLayer = layer;
      this.scaleControl = bar;
      if (this.showParticles === true && this.particlesLayer) {
        this.$parent.$parent.map.removeLayer(this.particlesLayer);
        this.$parent.$parent.map.addLayer(this.particlesLayer);
      }
    },
    cancel() {
      this.showTip = !this.showTip;
      this.errorMsg = '';
      this.loadError = false;
    },
    createPower() {
      if (!this.selectedPnt) {
        this.errorMsg = 'Debe seleccionar un punto en el mapa';
        return;
      }

      // validar variables extra del modulo
      if (!this.$refs.variablesControl.validate()) {
        this.errorMsg = 'Hay variables que contienen errores';
        return;
      }

      // se obtinen los datos geoJSON de la marker
      this.loadSolarEnergy();
    },
    mapClick(e) {
      if (this.showTip) {
        const lat = e.latlng.lat.toFixed(6);
        const lng = e.latlng.lng.toFixed(6);
        this.selectedPnt = {
          lat,
          lng,
        };
      }
    },
  },
};
</script>

<style scoped>
</style>
